@import 'styles/styles.scss';

.withdrawSuccess {
    .resultTop {
        width: 100%;

        .checkCircleIco {
            font-size: 128px;
            color: $COLOR_GREEN;
            width: 100%;
            margin: 50px 0 55px;
        }

        h2 {
            font-size: $FONTSIZE_24;
            text-align: center;
            color: $COLOR_TEXT;
        }

        h5 {
            font-size: $FONTSIZE_BODY;
            color: $COLOR_GRAY;
            text-align: center;
            margin: 40px 0 22px;
        }
    }
}
