@import 'styles/styles.scss';

.dialogSuspendAlert {
    .alertContent {
        margin-bottom: 20px;

        p {
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: $COLOR_TEXT;
        }

        .recommended {
            margin-top: 10px;

            .recommendedLogoWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .recommendedLogo {
                width: 100px;
                padding-top: 10px;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .btnWrapper {
        max-width: 150px;
        margin: 0 auto;
    }
}
