@import 'styles/styles.scss';

.introWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;

    p {
        text-align: center;
        margin-bottom: 20px;
    }
}
