@import 'styles/styles.scss';

.videoActions {
    width: 100%;
    z-index: 9;

    .countdownNo {
        font-size: $FONTSIZE_32;
    }

    .actionDesc {
        margin-top: 42px;
    }
}
