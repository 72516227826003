@import 'styles/styles.scss';

.withdrawConfirm {
    .withdrawAmount {
        width: 100%;
        margin: 2rem 0 2rem;

        h2 {
            margin: 2rem 0 0.625rem 0;
            width: 100%;
            color: $COLOR_ORANGE;
            font-size: 2.25rem;
            font-weight: $FONTWEIGHT_BOLD;
            text-align: center;
        }

        p {
            margin: 0.625rem 0 1rem 0;
            width: 100%;
            color: $COLOR_GRAY;
            font-size: 1rem;
            font-weight: $FONTWEIGHT_BOLD;
            text-align: center;
        }
    }

    .divider {
        margin-bottom: 15px;
    }

    .verifyCode {
        display: flex;
        justify-content: space-between;

        .textField {
            width: calc(100% - 22%);
        }

        .verifyCodBtn {
            width: 20%;
            margin-top: 28px;

            &.Mui-disabled {
                color: $COLOR_WHITE !important;
                opacity: 0.6;
            }
        }
    }

    .hints {
        color: $COLOR_BLUE;
        line-height: 1.5;
    }

    .btnWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .editBtn {
            width: 40%;
        }

        .confirmBtn {
            width: 55%;
        }
    }
}
