@import 'styles/styles.scss';

.unsupportedView {
    flex-direction: column;
    text-align: center;

    h2 {
        font-size: $FONTSIZE_24;
        margin-bottom: 20px;
    }

    h4 {
        font-size: $FONTSIZE_24;
        font-weight: $FONTWEIGHT_BOLD;
        color: $COLOR_ORANGE;
        margin: 80px 0 20px;
    }

    .qrCodeWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 180px;
        margin: 0 auto 30px;
        background-color: $COLOR_ORANGE;
        border-radius: 24px;
    }
    .qrCode {
        border-radius: 20px;
    }

    .btn {
        &.refreshBtn {
            margin-top: 40px;
        }
    }
}
