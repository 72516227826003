@import 'styles/styles.scss';

.videoActionsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
        line-height: 20px;
        margin-top: 42px;
    }

    .suggestList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px auto 0;
        width: 100%;

        .suggestItm {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .suggestIco {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            background-color: $COLOR_TAUPE_GREY;
            border-radius: 50%;
            margin-bottom: 14px;

            img {
                height: 37px;
            }
        }

        h5 {
            font-size: $FONTSIZE_BODY;
            text-align: center;
        }
    }

    .actionWrapper {
        margin-bottom: 60px;

        .actionList {
            list-style-position: inside;

            li {
                margin-top: 15px;
            }
        }
    }

    .recordBtn {
        width: 80%;
    }

    .stopBtn {
        background-color: $COLOR_RED;
        border-radius: 2px;
        min-width: auto;
        width: 30px;
        height: 30px;
        opacity: 0.8;

        &:after {
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 5px solid $COLOR_WHITE;
        }

        &:hover {
            background-color: $COLOR_RED;
        }
    }
}
