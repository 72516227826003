@import 'styles/styles.scss';

.errorMsg {
    display: flex;
    margin-top: 10px;

    p {
        font-size: $FONTSIZE_BODY;
        color: $COLOR_RED;
        margin-left: 5px;
    }
}
