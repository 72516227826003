@import 'styles/styles.scss';

.tabControl {
    position: relative;
    width: 100%;

    @include tabArrowBtn($COLOR_WHITE, $COLOR_BLACK);

    .MuiTabs-flexContainer {
        padding: 5px 0;

        .MuiButtonBase-root {
            font-size: $FONTSIZE_18;
            font-weight: $FONTWEIGHT_MEDIUM;
            color: $COLOR_BLUE;
            width: 100px;
            height: 42px;
            min-height: auto;
            border-radius: 10px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.27);
            margin-right: 10px;

            &.Mui-selected {
                color: $COLOR_WHITE;
                background-color: $COLOR_ORANGE;
            }
        }
    }

    .MuiTabs-indicator {
        display: none;
    }
}
