@mixin fieldBorder {
    background-color: $COLOR_WHITE;
    outline: none;
    padding-right: 0;
    width: 100%;
    height: 43px;
    border-radius: 10px !important;
    border: 1px solid rgba($COLOR_BLACK, 0.35);
    box-sizing: border-box !important;
}

@mixin fieldBorderFocused {
    &:focus,
    &.Mui-focused {
        border: 1px solid rgba($COLOR_ORANGE, 1);
        box-shadow: 0px 0px 6px rgba($COLOR_ORANGE, 0.5);
    }
}

@mixin fieldBorderStyle {
    @include fieldBorder();
    @include fieldBorderFocused();
}
