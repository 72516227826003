@import 'styles/styles.scss';

.withdrawFormWrapper {
    .withdrawForm {
        display: flex;
        flex-direction: column;
        width: 100%;

        .fieldItm {
            margin-bottom: 20px;
        }

        .tradeAccount {
            width: 100%;
        }

        .withdrawMethod {
            min-height: auto;

            .MuiTab-root {
                min-height: auto;
                height: 32px;
                margin-right: 15px;
                line-height: 32px;
                background-color: $COLOR_WHITE;
                border: 1px solid $COLOR_FIELD_BORDER;
                border-radius: 10px;
                font-size: 14px;
                color: $COLOR_TEXT;
            }

            .Mui-selected {
                border: 1px solid $COLOR_FIELD_FOCUS_BORDER;
                box-shadow: 0px 0px 6px rgba($COLOR_ORANGE, 0.5);
                backdrop-filter: blur(20px);
                background-color: $COLOR_ORANGE;
                color: $COLOR_WHITE;
                outline: none;
            }
        }

        .withdrawAccount {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .addAccount {
                width: 100px;
                // height: 32px;
                margin-top: 5px;
                padding: 4px 0px;
                background-color: $COLOR_WHITE;
                align-items: center;
                color: $COLOR_TEXT;
                border: 1px solid $COLOR_FIELD_BORDER;
                border-radius: 10px;

                p {
                    margin-top: 2px;
                    margin-left: 4px;
                }

                .icon {
                    color: $COLOR_RED;
                    font-size: 1.5rem;
                }
            }

            .selectField {
                width: calc(100% - 110px);
            }
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
