@import 'styles/styles.scss';

.verifyWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $COLOR_WHITE;

    h3 {
        font-size: $FONTSIZE_24;
        margin-bottom: 40px;
    }

    p {
        font-size: 14px;
        color: $COLOR_GRAY;
        margin-bottom: 20px;
    }

    .resultIco {
        width: 113px;
        margin-bottom: 50px;
    }
}
