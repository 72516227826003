@import 'styles/styles.scss';

.depositLiveness {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 12px;

    .videoRecorderWrapper {
        padding: 30px 0;
        min-height: 500px;
        background-color: transparent;
        color: $COLOR_TEXT;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        video {
            position: relative;
            left: auto;
            min-width: auto;
            min-height: auto;
            width: 260px;
            height: 260px;
            border-radius: 50%;
            overflow: hidden;
            transform: translate(-0%, -0%) scaleX(-1);

            ~ div {
                display: none;
            }
        }

        .videoBtn {
            margin: 20px auto 0;
        }
    }
}
