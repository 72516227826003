@import './styles.scss';

@import './components/field.scss';

* {
    margin: 0;
    padding: 0;
    line-height: 1;
    box-sizing: border-box;
}

html,
body,
#root {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $FONT_PRIMARY;
    font-size: $FONTSIZE_16;
    font-weight: $FONTWEIGHT_NORMAL;
    width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

hr {
    &.divider {
        background-color: $COLOR_WHITE;
    }
}

img {
    display: block;
    width: 100%;
}

.paymentWrapper {
    width: 100%;
    max-width: 500px;
    margin: 30px 0 50px;
    padding: 0 12px;
}

.fieldItm {
    margin-bottom: 20px;
}

.resultList {
    &.MuiCard-root {
        width: 100%;
        padding: 15px;
        background-color: $COLOR_TEXT;
        color: $COLOR_WHITE;
        border-radius: 10px;
        margin-bottom: 40px;
    }
}

.resultListItm {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        display: flex;
        align-items: center;

        &.value {
            max-width: 60%;
            margin-left: 30px;
            text-align: right;
            word-break: break-word;
        }

        .reminderIco {
            margin-left: 8px;
            cursor: pointer;
        }
    }
}

.MuiButton-root {
    &.btn {
        width: 100%;
        margin-top: 0.5rem;
        width: 100%;
        height: 2.5625rem;
        color: $COLOR_WHITE;
        font-size: 1.125rem;
        border-radius: 10px;

        &.primary {
            background-color: $COLOR_ORANGE;

            &:active,
            &:focus {
                background-color: $COLOR_WHITE;
                color: $COLOR_ORANGE;
                border: 1px solid $COLOR_ORANGE;
            }
        }

        &.secondary {
            background-color: $COLOR_BLUE;
            border-color: $COLOR_BLUE;

            &:active,
            &:focus {
                background-color: $COLOR_WHITE;
                color: $COLOR_BLUE;
            }
        }
    }
}

.errorItm {
    margin-top: 10px;
}

.iconReminder {
    display: flex;
    margin-top: 20px;
    color: $COLOR_TEXT;
    cursor: pointer;

    svg {
        margin-right: 4px;
    }
}

.fullScreenCenter {
    display: flex;
    align-items: center;
    height: 100vh;
}
