@import 'styles/styles.scss';

.appDialog {
    .MuiBackdrop-root {
        backdrop-filter: blur(4px);
    }

    .MuiPaper-root {
        max-width: none;
        min-width: 350px;
        border-radius: 10px;
        overflow-x: hidden;

        .dialogHeader {
            display: flex;
            flex-wrap: wrap;
            position: sticky;
            top: 0;
            align-items: center;
            justify-content: space-between;
            padding: 24px 20px;
            background-color: $COLOR_WHITE;

            .dialogTitle {
                position: relative;
                display: flex;
                align-items: center;
                padding: 0;
                font-size: 24px;
                font-weight: $FONTWEIGHT_BOLD;

                &:before {
                    content: '';
                    align-self: stretch;
                    width: 4px;
                    margin: -4px 8px -4px 0;
                    background-color: $COLOR_ORANGE;
                    border-radius: 2px;
                }
            }
        }

        .dialogContent {
            height: 100%;
            padding: 5px 20px 30px;
        }
    }
}
