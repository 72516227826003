@import 'styles/styles.scss';

.gatewayImageBtn {
    &.MuiButton-root {
        height: 50px;
        padding: 10px;
        background-color: $COLOR_WHITE;
        outline: 1px solid $COLOR_SILVER_LIGHT;
        box-shadow: 0px 0px 6px rgba($COLOR_BLACK, 0.07);
        border-radius: 5px;
        overflow: hidden;

        &.active {
            outline: 4px solid $COLOR_LIGHTORANGE;

            .gatewayLogo {
                opacity: 1;
            }
        }

        img {
            max-width: 100%;
        }

        .gatewayLogo {
            opacity: 0.7;
        }

        .recentlyUsed {
            position: absolute;
            width: 100%;
            bottom: 0;
            background-color: $COLOR_SMOKEGREY;
            border-radius: 0 0 5px 5px;
        }

        .recentlyUsedTxt {
            font-size: $FONTSIZE_12;
            color: $COLOR_WHITE;
            line-height: 1;
            transform: scale(0.7);
        }

        .suggestedIco {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 18px;
        }
    }
}
