@mixin desktop {
    @media (min-width: #{$TABLET_BREAKPOINT}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$TABLET_BREAKPOINT}) {
        @content;
    }
}

@mixin tabArrowBtn($bgColor, $iconColor) {
    .MuiIconButton-root {
        position: absolute;
        top: 0;
        width: 30px;
        height: 100%;
        background-color: $bgColor;
        border-radius: 0;
        z-index: 99;

        &.arrowForward {
            right: 0;
            padding-right: 0;
            justify-content: flex-end;
            background: linear-gradient(
                90deg,
                rgba($bgColor, 0) 0%,
                rgba($bgColor, 1) 100%
            );
        }

        &.arrowBack {
            left: 0;
            padding-left: 0;
            justify-content: flex-start;
            background: linear-gradient(
                90deg,
                rgba($bgColor, 1) 0%,
                rgba($bgColor, 0) 100%
            );
        }

        svg {
            color: $iconColor;
        }
    }
}
