@import 'styles/styles.scss';

.disconnectedView {
    .profileWrapper {
        position: relative;
    }

    .profilePicImg {
        width: 260px;
        height: 260px;
        background-color: #eee;
        margin-bottom: 70px;
        border-radius: 50%;
    }

    h3 {
        font-size: $FONTSIZE_18;
        margin-bottom: 20px;
        line-height: 1.5;
    }

    ol {
        list-style-position: inside;
        li {
            padding-bottom: 15px;
        }
    }
}
