@import 'styles/styles.scss';

.videoActionsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 14px;
    }
}
