@import 'styles/styles.scss';

.dialogContentUSDTFee {
    h5 {
        margin-bottom: 20px;
    }

    .feeDetailList {
        margin-bottom: 30px;

        .listItm {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;
        }
    }

    .btnWrapper {
        max-width: 200px;
        margin: 0 auto;
    }
}
