@import 'styles/styles.scss';

.depositResult {
    .depositResultWrapper {
        width: 100%;
        display: grid;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-bottom: 50px;

        .resultIco {
            margin-top: 45px;
            width: 113px;
            height: 113px;
        }

        h2 {
            font-size: 24px;
            text-align: center;
            color: $COLOR_TEXT;
            margin-top: 50px;
        }
    }

    .resultList {
        margin: 80px 0 74px;

        .listItm {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        h4 {
            font-size: $FONTSIZE_16;
            font-weight: $FONTWEIGHT_BOLD;
            color: $COLOR_ORANGE;
        }

        p {
            font-size: $FONTSIZE_BODY;
        }
    }
}
