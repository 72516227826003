@import 'styles/styles.scss';

.cameraSpinnerWrapper {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -142px;
    width: 288px;
    height: 288px;
    transform: rotate(-90deg);

    .spinnerItm {
        position: absolute;
        width: 12px;
        height: 2px;
        background-color: $COLOR_TAUPE_GREY;
        animation-duration: 4s;
        animation-fill-mode: forwards;
        animation-timing-function: step-start;
    }
}

@keyframes spinner {
    0% {
        background-color: $COLOR_TAUPE_GREY;
    }
    100% {
        background-color: $COLOR_ORANGE;
    }
}
