$COLOR_ORANGE: #f39200;
$COLOR_TIGERORANGE: #fe6c04;
$COLOR_LIGHTORANGE: #ffb348;
$COLOR_BLUE: #030934;
$COLOR_INDIGO: #091462;
$COLOR_TEXT: #030934;
$COLOR_WHITE: #ffffff;
$COLOR_BLACK: #000000;
$COLOR_SMOKEGREY: #c5c5c5;
$COLOR_TAUPE_GREY: #bdbdbd;
$COLOR_SILVER_LIGHT: #d9d9d9;
$COLOR_RED: #e30613;
$COLOR_DANGERRED: #e00000;
$COLOR_DARK_GUNMETAL: #1a1c2d;
$COLOR_GRAY: #868686;
$COLOR_GREEN: #52c41a;
$COLOR_LIGHTGRAY: #8e8e93;

$BUTTON_COLOR_PRIMARY: $COLOR_ORANGE;
$BUTTON_COLOR_SECONDARY: $COLOR_BLUE;

$COLOR_FIELD_BORDER: #c4c4c4;
$COLOR_FIELD_FOCUS_BORDER: $COLOR_ORANGE;
$COLOR_FIELD_HOVER_BORDER: #c4c4c4;

/*=============================================
=                  Font Families              =
=============================================*/
$FONT_PRIMARY: 'Arial', 'NotoSansSC';

$FONTSIZE_LABEL: 1rem;

/*=============================================
=                  Font Sizes                 =
=============================================*/
$FONTSIZE_10: 10px;
$FONTSIZE_12: 12px;
$FONTSIZE_BODY: 14px;
$FONTSIZE_15: 15px;
$FONTSIZE_16: 16px;
$FONTSIZE_18: 18px;
$FONTSIZE_24: 24px;
$FONTSIZE_20: 20px;
$FONTSIZE_28: 28px;
$FONTSIZE_32: 32px;
$FONTSIZE_36: 36px;

$FONTWEIGHT_NORMAL: 400;
$FONTWEIGHT_MEDIUM: 500;
$FONTWEIGHT_SEMIBOLD: 600;
$FONTWEIGHT_BOLD: 700;
$FONTWEIGHT_EXTRABOLD: 800;
$FONTWEIGHT_BLACK: 900;

/*=============================================
=                  Breakpoints                =
=============================================*/
$MOBILE_BREAKPOINT: 550px;
$TABLET_BREAKPOINT: 1320px;
