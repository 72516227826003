@import 'styles/styles.scss';

.depositForm {
    .depositAmountBox {
        .depositAmount-select {
            width: 100%;
            line-height: 20px;
            background-color: $COLOR_WHITE;
            font-size: 15px;
            color: $COLOR_TEXT;
            border-radius: 10px;
            box-shadow: 0px 0px 5px rgba($COLOR_BLUE, 0.2);
            backdrop-filter: blur(20px);

            &:focus,
            &.active {
                background: $COLOR_ORANGE;
                color: $COLOR_WHITE;
                font-weight: $FONTWEIGHT_BOLD;
                box-shadow: 0px 0px 5px rgba($COLOR_ORANGE, 0.5);
            }
        }
    }

    .depositAmount {
        .MuiOutlinedInput-input {
            line-height: 20px;
        }

        .inputAmountAdornment {
            margin: 0;
            margin-right: 10px;
            vertical-align: middle;
        }
    }

    .remarkWrapper {
        li {
            color: $COLOR_TEXT;
        }
    }
}
