.MuiOutlinedInput-root {
    &.MuiInputBase-root {
        @include fieldBorderStyle();
        color: $COLOR_TEXT !important;

        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 50px #{$COLOR_WHITE} inset;
            box-sizing: border-box;
        }

        .MuiSelect-select {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 43px;
            box-sizing: border-box;
        }

        &:after,
        &:before {
            display: none;
        }

        .MuiInputBase-input {
            &::placeholder {
                padding-bottom: 2px;
            }
        }

        .MuiInputAdornment-root {
            height: auto;
        }

        .MuiSvgIcon-root {
            font-size: 20px;
            right: 10px;
            color: $COLOR_LIGHTGRAY;
        }
    }
}

.MuiMenu-root {
    .MuiPaper-root {
        margin: 8px 0;
        background-color: rgba($COLOR_BLACK, 0.8);
        backdrop-filter: blur(4px);
        border-radius: 10px;

        .MuiList-root {
            max-height: 250px;
        }

        .MuiMenuItem-root {
            color: $COLOR_WHITE;
            margin: 4px 10px;
            padding-left: 6px;
            border-radius: 10px;
            white-space: normal;

            &.Mui-selected {
                background-color: rgba($COLOR_ORANGE, 0.3);
            }

            &:hover {
                background-color: $COLOR_ORANGE;
            }
        }
    }
}
